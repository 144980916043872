//Join Us Form
$('.join-us.submit').on('click', function () {
	$('#join-us-now').submit();
});

$('.interested.submit').on('click', function () {
	$('#interested-form').submit();
});

$('.recruitment-btn.submit').on('click', function(e) {
	
  var flag = false;
  $('.invalid-feedback').hide(); //Validate Name
	
	if(!flag && document.getElementById('fullname').value.trim() == "") {
		document.getElementById('validateFullname').style.display = 'block';
		flag = true;
		document.getElementById('fullname').focus();
	} //Validate Fullname
	
	if(!flag && document.getElementById('email').value.trim() == "") {
		document.getElementById('validateEmail').style.display = 'block';
		flag = true;
		document.getElementById('email').focus();
	} //Validate Email
	
	var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
	if(!flag && document.getElementById('email').value.trim() != "" && !document.getElementById('email').value.trim().match(emailReg)) {
		document.getElementById('validateInvalidEmail').style.display = 'block';
		flag = true;
		document.getElementById('email').focus();
	} //Validate Email
	
	if(!flag && document.getElementById('nric_passport').value.trim() == "") {
		document.getElementById('validateNRICPassport').style.display = 'block';
		flag = true;
		document.getElementById('nric_passport').focus();
	} //Validate NRIC/Passport
	
	if(!flag && document.getElementById('contact_number_country').value.trim() == "") {
		document.getElementById('validateCountryCode').style.display = 'block';
		flag = true;
		document.getElementById('contact_number_country').focus();
	} //Validate Mobilecountrycode
	
	if(!flag && document.getElementById('contact_number_mobile').value.trim() == "") {
		document.getElementById('validateContactNo').style.display = 'block';
		flag = true;
		document.getElementById('contact_number_mobile').focus();
	} //Validate MobileNo
	
	var mobilenoReg = /^[0-9\+]{7,20}$/;
	if(!flag && document.getElementById('contact_number_mobile').value.trim() != "" && !document.getElementById('contact_number_mobile').value.trim().match(mobilenoReg)) {
		document.getElementById('validateInvalidContactNo').style.display = 'block';
		flag = true;
		document.getElementById('contact_number_mobile').focus();
	} //Validate MobileNo
	
	if(!flag && document.getElementById('bank_id').value.trim() == "") {
		document.getElementById('validateBank').style.display = 'block';
		flag = true;
		document.getElementById('bank_id').focus();
	} //Validate bank
	
	if(!flag && document.getElementById('bank_account_no').value.trim() == "") {
		document.getElementById('validateBankAccountNo').style.display = 'block';
		flag = true;
		document.getElementById('bank_account_no').focus();
	} //Validate bank
		
	if(!flag && document.getElementById('registration_payment_receipt').files.length == 0) {
		document.getElementById('validatePaymentReceipt').style.display = 'block';
		flag = true;
		document.getElementById('registration_payment_receipt').focus();
	} //Validate registration payment receipt
		
	if(!flag && document.getElementById('registration_identity_document').files.length == 0) {
		document.getElementById('validateIdentityDocument').style.display = 'block';
		flag = true;
		document.getElementById('registration_identity_document').focus();
	} //Validate identity document
		
	if(!flag)
	$('#recruitment-form').submit();
	else
	e.preventDefault();
})

// $(document).on('submit', 'form', function (e) {
// 	var $form = $(this);

// 	console.log($form);
// 	console.log($(this));
// });

$("[name='ffscountry']").on("change", function () {
	// $("[name='contact_no']").val($(this).find("option:selected").data("countrycode"));
	
	$("[name='countrycode']").val($(this).find("option:selected").data("countrycode"));
});

$("[name='contact_number_country']").on("change", function () {
  // $("[name='contact_no']").val($(this).find("option:selected").data("countrycode"));
  $("[name='contact_number_country_code_mobile']").val($(this).find("option:selected").data("countrycode"));
});

$("[name='namecard_contact_number_country']").on("change", function () {
  // $("[name='contact_no']").val($(this).find("option:selected").data("countrycode"));
  $("[name='name_card[contact_number_country_code]']").val($(this).find("option:selected").data("countrycode"));
});

$(document).on('submit', 'form', function (e) {
	// $('#interested-form').on('submit', function (e) {
	// $('#join-us-now').on('submit', function (e) {

	var interestForm = document.querySelector('#interested-form');
	var joinUsForm = document.querySelector('#join-us-now');
	var recruitmentForm = document.querySelector('#recruitment-form')

	e.preventDefault();
	if (e.currentTarget == interestForm) { togglePreloader($(this)); }
	let flag = false;

	$('.invalid-feedback').hide();

	if (e.currentTarget != recruitmentForm) {
		//Validate Name
		if (this.ffsname.value.trim() == "") {
			if (e.currentTarget == interestForm) {
				document.getElementById('validateNameModal').style.display = 'block';
			}
			if (e.currentTarget == joinUsForm || e.currentTarget == recruitmentForm) {
				document.getElementById('validateName').style.display = 'block';
			}
			flag = true;
			this.focus();
		}
	
		//Validate Email
		var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
		if ((this.ffsemail.value.trim() == "")) {
			if (e.currentTarget == interestForm) {
				document.getElementById('validateEmailModal').style.display = 'block';
			}
			if (e.currentTarget == joinUsForm || e.currentTarget == recruitmentForm) {
				document.getElementById('validateEmail').style.display = 'block';
			}
			flag = true;
			this.focus();
		} else if (!((this.ffsemail.value.trim()).match(emailReg))) {
			if (e.currentTarget == interestForm) {
				document.getElementById('validateEmailModal').style.display = 'block';
			}
			if (e.currentTarget == joinUsForm || e.currentTarget == recruitmentForm) {
				document.getElementById('validateEmail').style.display = 'block';
			}
			flag = true;
			this.focus();
		}
	
		//Validate Country Code
		if (this.ffscountry.value.trim() == "") {
			const countryCode = document.querySelector('#validateCountryCode')
			
			if (!countryCode) return
			if (e.currentTarget == joinUsForm || e.currentTarget == recruitmentForm) {
				document.getElementById("validateCountryCode").style.display = "block";
			} else {
				document.getElementById("validateCountryCode").style.display = "none";
			}
	
			const countryCodeModal = document.querySelector('#validateCountryCodeModal')
	
			if (!countryCodeModal) return
			if (e.currentTarget == interestForm) {
				document.getElementById("validateCountryCodeModal").style.display = "block";
			} else {
				document.getElementById("validateCountryCodeModal").style.display = "none";
			}
		}
	
		//Validate Contact No.
		if (this.contact_no.value.trim() != "") {
			var mobilenoReg = /^[0-9\+]{7,20}$/;
			if (this.contact_no.value.match(mobilenoReg)) {
				if (e.currentTarget == interestForm) {
					document.getElementById("validateInvalidContactNoModal").style.display = "none";
				}
				if (e.currentTarget == joinUsForm || e.currentTarget == recruitmentForm) {
					document.getElementById("validateInvalidContactNo").style.display = "none";
				}
			} else {
				if (e.currentTarget == interestForm) {
					document.getElementById("validateInvalidContactNoModal").style.display = "block";
				}
				if (e.currentTarget == joinUsForm || e.currentTarget == recruitmentForm) {
					document.getElementById("validateInvalidContactNo").style.display = "block";
				}
				flag = true;
				this.contact_no.focus();
			}
		} else {
			if (e.currentTarget == interestForm) {
				document.getElementById("validateContactNoModal").style.display = "block";
			}
			if (e.currentTarget == joinUsForm || e.currentTarget == recruitmentForm) {
				document.getElementById("validateContactNo").style.display = "block";
			}
			flag = true;
		}
	}
	// console.log('flag' + flag);

	if (!flag) {
		this.submit();
	} else {
		if (e.currentTarget == interestForm) { togglePreloader($(this)); }
	}
});

const togglePreloader = (ele) => {
	ele[0].querySelector('.preloader').classList.toggle('inactive');
}