import Swiper from 'swiper/bundle';

(function () {
    const body = document.querySelector('body')
    const home = document.querySelector('#home')

    if (body == home) {
        initScrollMagic();
        initSwiper();
        initScroll();
        initFadeIcons();
        // initLightBox();
        initPauseVideo();
        pulseHover()
    }
})();

function pulseHover() {
    const pulseBox = document.querySelectorAll('.pulse-box')
    
    pulseBox.forEach(ele => {
        const pulseCSS = ele.querySelector('.pulse-css')
        const pulseName = ele.querySelector('.pulse-name')

        pulseCSS.addEventListener('mouseenter', () => {
            pulseName.classList.add('active')
        })

        pulseCSS.addEventListener('mouseleave', () => {
            pulseName.classList.remove('active')
        })
    })
}

function initScrollMagic() {
    // init controller
    const controller = new ScrollMagic.Controller();

    const maskTween = anime.timeline();
    maskTween.add({
        targets: '.slogan',
        duration: 1000,
        opacity: [1, 0],
        easing: 'linear',
    })
        .add({
            targets: '.container-intro',
            duration: 1000,
            translateY: [0,-120],
            opacity: [0, 1],
        })

        var width = window.innerWidth;

    if (width > 768) {
        const scene = new ScrollMagic.Scene({triggerElement: ".section-wrapper", duration: 110, triggerHook: 0})
        // .addIndicators()
        .setAnime(maskTween)
        .setPin(".section-wrapper", {pushFollowers: false})
        .addTo(controller);
    } else if (width <= 768) {
        const scene = new ScrollMagic.Scene({triggerElement: ".section-wrapper", duration: 65, offset: 0.1, triggerHook: 0})
        // .addIndicators()
        .setAnime(maskTween)
        .setPin(".section-wrapper", {pushFollowers: false})
        .addTo(controller);
    }
}

function initSwiper() {

    var peopleSwiper = new Swiper('.swiper-container', {
        slidesPerView: 'auto',
        autoHeight: true,
        // loopAdditionalSlides: 5,
        // centeredSlides: true,
        // loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });
}

function initScroll() {
    //Scroll to top
    $(window).scroll(function () {
        if ($(this).scrollTop() > 500) {
            $('#back-to-top').fadeIn();
        } else {
            $('#back-to-top').fadeOut();
        }
    });

    $(function () {
        $('#back-to-top').click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, '1000'); return false
        })
    });
}

function initFadeIcons() {

    var icon_bar = $('#icon-bar'),
        extra = 150;

    var staticLength = ($(window).height() + extra) + $(window).scrollTop(),
    documentHeight = $(document).height();

    if (staticLength >= $(document).height()) {
        $(icon_bar).fadeOut();
    }

    $(window).scroll(function () {

        var scrolledLength = ($(window).height() + extra) + $(window).scrollTop();

        if (scrolledLength >= documentHeight) {
            $(icon_bar).fadeOut();
        } else {
            $(icon_bar).fadeIn();
        }
    });
}

function initLightBox() {
    // $('#modal1').on('hidden.bs.modal', function (e) {
    //     $('#modal1 video').attr("src", $("#modal1 video").attr("src"));
    // });
    // $('#modal2').on('hidden.bs.modal', function (e) {
    //     $('#modal2 video').attr("src", $("#modal2 video").attr("src"));
    // });
}

function initPauseVideo() {
    var vidCarousel = $('.video-thumb');

    vidCarousel.each(function (index, value) {
        
        var thumb = $(this).find('#video_thumbnail');

        thumb.click(event => {
            var iframe = $("#iframe" + (index + 1));
            var url = iframe.attr('src');

            $('#modal' + (index + 1)).on('hidden.bs.modal', function (e) {
                // $('#modal' + (index + 1) + 'video').attr("src", $("#modal" +(index + 1) + "video").attr("src"));
                iframe.attr('src', url);
            });

            $('#modal' + (index + 1)).click(event => {
                var modal = $("#modal" + (index + 1));
                modal.on('hide.bs.modal', function(){
                    iframe.attr('src', '');
                });
            });
        });
    });
}